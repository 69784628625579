#contact {
    h2,
    h3 {
        color: #fff;
        line-height: 1.6;
    }
    a {
        color: #67d4b0;
        text-decoration: none;
        font-size: 1.2em;
    }
    .form-box {
        height: 70vh;
    }
}

form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 2rem;

    input {
        padding: 0.75rem 0.5rem;
        margin: 0 0.2rem;
    }
    input[type="submit"] {
        background-color: #67d4b0;
        border-radius: 7px;
        border-color: transparent;
        // width: 150px;
        // height: 40px;
    }
    input[type="submit"]:hover {
        font-size: 1.1em;
        transition: font-size 0.5s ease-in-out;
    }

    p {
        color: white;
        position: absolute;
        margin-top: 10px;
    }
}

section#contact {
    background-color: #212529;
    background-image: url("../assets/images/map-image.png");
    background-repeat: no-repeat;
    background-position: center;
}
section#contact .section-heading {
    color: #fff;
}
section#contact form#contactForm .form-group {
    margin-bottom: 1.5rem;
}
section#contact form#contactForm .form-group input,
section#contact form#contactForm .form-group textarea {
    padding: 1.25rem;
}
section#contact form#contactForm .form-group input.form-control {
    height: auto;
}
section#contact form#contactForm .form-group-textarea {
    height: 100%;
}
section#contact form#contactForm .form-group-textarea textarea {
    height: 100%;
    min-height: 10rem;
}
section#contact form#contactForm p.help-block {
    margin: 0;
}
section#contact form#contactForm .form-control:focus {
    border-color: #00e7ff;
    box-shadow: none;
}
section#contact form#contactForm ::-webkit-input-placeholder {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    color: #ced4da;
}
section#contact form#contactForm :-moz-placeholder {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    color: #ced4da;
}
section#contact form#contactForm ::-moz-placeholder {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    color: #ced4da;
}
section#contact form#contactForm :-ms-input-placeholder {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    color: #ced4da;
}
