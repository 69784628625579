html,
body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    color: #2c3e50;
    background-color: #f6f6f0;
    box-sizing: border-box;
    // box-sizing: content-box;
    font-family: "Montserrat", sans-serif;
    // font-family: "Nunito", sans-serif;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#root {
    margin: 0 auto;
    width: 100%;
}

.wrapper {
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    position: relative;
}

header,
footer {
    width: 100vw;
    max-width: 1920px;
    margin: 0 auto;
}

.main-content {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    max-width: 1920px;
    // margin-top: 80px;
    box-sizing: border-box;

    p {
        word-break: break-word;
    }
}

.App {
    text-align: center;
}

h1,
h1 > a {
    font-family: "Montserrat", sans-serif;
    // font-family: "Bebas Neue", sans-serif;
    text-transform: uppercase;
    font-size: 1.5rem;
    letter-spacing: 2.9px;
    color: #2c3e50;
    font-weight: 500;
    text-decoration: none;
    line-height: 1.9;
}

h2,
h3 {
    font-weight: 400;
    // font-family: "Droid Serif", sans-serif;
}
p {
    line-height: 1.6;
}

.link {
    color: #2c3e50;
}

.menu-left {
    display: flex;
    padding-right: 5%;
}

.app-logo {
    width: 17vh;
    padding: 22px 7px 7px 7px;
    cursor: pointer;
}

.nav-con {
    display: flex;
    justify-content: space-between;
}

.nav-link,
.nav-link > a {
    color: #fff;
    background-color: #212529;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 0.5rem;
    // height: 100%;
    margin: 0 1rem;
    cursor: pointer;
    &.active {
        color: #00e7ff;
    }
}

// .scale:hover {
//     -webkit-animation: scale all 1.5s linear;
//     animation: scale all 1.5s linear;
//     transition: all 1.5s linear;
//     transform: scale(80%);
// }

// @-webkit-keyframes scale {
//     from {
//         -webkit-transform: scale(80%);
//         transform: scale(80%);
//     }
//     to {
//         -webkit-transform: scale(100%);
//         transform: scale(100%);
//     }
// }

// @keyframes scale {
//     from {
//         -webkit-transform: scale(80%);
//         transform: scale(80%);
//     }
//     to {
//         -webkit-transform: scale(100%);
//         transform: scale(100%);
//     }
// }
//    from {
//         -webkit-transform: translateX(200px);
//         transform: translateX(200px);
//     }
//     to {
//         -webkit-transform: translateX(0px);
//         transform: translateX(0px);
//     }

.con,
.box {
    display: flex;
    flex-grow: 1;
    margin: 0 auto;
    flex-wrap: wrap;
    width: 100%;
}

.con {
    min-height: 100%;
}

.box {
    justify-content: center;
    padding-bottom: 120px;
    height: 100%;
    background-color: #fff;
}

.highlight {
    color: #007bff;
}

.cards-box {
    display: flex;
    justify-content: center;
    max-width: 100%;
    flex-wrap: wrap;
    margin: 0 auto;
    padding-bottom: 80px;
}

.gallery-con {
    display: flex;
    width: 700px;
    height: 450px;
    border-radius: 0.25rem;
    flex-direction: column;
    height: 100%;
    margin: 0 auto;
    padding: 5%;

    &.float:hover {
        transform: translateY(-8px);
        box-shadow: 0 10px 10px -10px rgba(#7f8c8d, 1);
    }
    &:focus {
        outline: 2px solid #007bff;
    }

    .image {
        width: 100%;
        object-fit: cover;
        margin-bottom: 40px;
    }

    .gallery {
        display: flex;
        flex-flow: row;
        flex: 0 0 auto;
        height: 100%;
        overflow: hidden;
        width: 700px;
    }
}

.button {
    padding: 0.75rem 1.5rem;
    font-size: 0.75rem;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #000000;
    background-color: #ffffff;
    border: 2px solid black;
    border-radius: 0.25rem;
    transition: 0.25s;
    width: 210px;
    align-self: center;
    margin-top: 20px;

    &:hover,
    &:focus,
    &:active {
        // background-color: #007bff80;
        background: linear-gradient(287deg, #3cb371, #0fa7ee);
        border-color: transparent;
    }
}

.top {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #000000;
    background-color: #ffffff;
    position: fixed;
    right: 15px;
    bottom: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #261e1e;
    z-index: 3000;
    font-size: 0.75rem;
}

.text-box {
    width: 65%;
}

.line-break {
    white-space: pre-line;
    vertical-align: bottom;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;
    flex-flow: wrap;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3000;
}

.background-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    // height: 100%;
}

#mainNav {
    // padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #212529;
    width: 100vw;
}
#mainNav .navbar-toggler {
    padding: 0.75rem;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.75rem;
    color: #fff;
    text-transform: uppercase;
    background-color: #00e7ff;
    border: 0;
}
#mainNav .navbar-brand {
    color: #00e7ff;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    letter-spacing: 0.0625em;
    text-transform: uppercase;
}
#mainNav .navbar-brand img {
    height: 1.5rem;
}
#mainNav .navbar-nav .nav-item .nav-link {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.95rem;
    color: #fff;
    letter-spacing: 0.0625em;
}
#mainNav .navbar-nav .nav-item .nav-link.active,
#mainNav .navbar-nav .nav-item .nav-link:hover {
    color: #00e7ff;
}

.responsive-nav-con {
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100vw;
    height: 3rem;
}

@media (min-width: 992px) {
    #mainNav {
        padding-bottom: 1.5rem;
        border: none;
        background-color: transparent;
        transition: padding-top 0.3s ease-in-out,
            padding-bottom 0.3s ease-in-out;
    }
    #mainNav .navbar-brand {
        font-size: 1.5em;
        transition: font-size 0.3s ease-in-out;
    }
    #mainNav .navbar-brand img {
        height: 2rem;
        transition: height 0.3s ease-in-out;
    }
    #mainNav.navbar-shrink {
        padding-top: 1rem;
        padding-bottom: 1rem;
        background-color: #212529;
        width: 100vw;
        height: 90px;
        .app-logo {
            width: 15vh;
        }
        a {
            background-color: transparent;
        }
    }
    #mainNav.navbar-shrink .navbar-brand {
        font-size: 1.25em;
    }
    #mainNav.navbar-shrink .navbar-brand svg,
    #mainNav.navbar-shrink .navbar-brand img {
        height: 1.5rem;
    }
    #mainNav .navbar-nav .nav-item {
        margin-right: 1rem;
    }
    #mainNav .navbar-nav .nav-item:last-child {
        margin-right: 0;
    }
}

@media (min-width: 768px) {
    header.masthead {
        padding-top: 17rem;
        padding-bottom: 12.5rem;
    }
    header.masthead .masthead-subheading {
        font-size: 2.25rem;
        font-style: italic;
        line-height: 2.25rem;
        margin-bottom: 2rem;
    }
    header.masthead .masthead-heading {
        font-size: 4.5rem;
        font-weight: 700;
        line-height: 4.5rem;
        margin-bottom: 4rem;
    }
}

.nav {
    justify-content: flex-end;
}

.nav-menu {
    display: flex;
    align-items: center;
    height: 100%;

    a {
        // background-color: #37353b;
        cursor: pointer;
        color: #ffffff;
        padding: 0 1rem;
        margin: 0 1%;
        width: 100%;

        &hover {
            color: #ffffff;
        }
    }

    @media screen and (max-width: 768px) {
        display: none;
    }
}

.lang-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color: #000000;
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    background-color: transparent;
    background-color: #fff;
    width: 25px;
    height: 25px;
    margin: 0.5rem;
    font-weight: bold;
    text-align: center;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #67d4b0;
        color: #212529;
    }
}

.rtl-text {
    direction: rtl;
    text-align: right;
    unicode-bidi: embed;
}

.lrt-text {
    direction: ltr;
    text-align: left;
    unicode-bidi: embed;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #000000;
    color: #fff;
    font-size: 0.8em;
    height: 130px;
    width: 100vw;
    position: fixed;
    bottom: 0;

    box-shadow: 6px -6px 6px 0px rgb(32 31 31 / 24%);
    -webkit-box-shadow: -4px -6px 6px 0px rgb(32 31 31 / 24%);
    flex: none;

    a {
        color: #fff;
        text-decoration: none;
    }
    .footer-box {
        display: flex;
        width: 80%;
        padding: 1% 0;
        min-height: 30px;
    }
    .footer-box.first {
        justify-content: center;
        background-image: linear-gradient(287deg, #3cb371, #0fa7ee);
        width: 100%;
        padding: 1% 0;
        flex-wrap: wrap;
        min-height: 50px;
    }
    .footer-box.second {
        width: 70%;
        margin: 1% 0;
        min-height: 5rem;
    }

    .footer-link {
        margin: 0 5%;
    }
}

.nav-icon {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.icon {
    width: 12%;
    padding: 3%;
}
.link-con {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px;
}

.open-nav {
    position: fixed;
    width: 40%;
    background-color: #212529;
    right: 0;
    top: 0px;
    z-index: 300;
    width: 100%;
    padding-top: 5rem;
    nav {
        display: flex;
        z-index: 300;
        top: 110px;
        flex-direction: column;
        text-align: left;
        justify-content: center;
        align-items: flex-start;
    }
    a {
        color: #f8f6f5;
        padding: 1rem 2rem;
        letter-spacing: 0.0625em;
        text-transform: uppercase;
        margin-top: 0.5rem;
        &:hover,
        &:focus,
        &:active {
            background-color: #66d5b0;
        }
    }
}

.lang-box {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    padding-top: 20px;
    .lang-btn {
        margin-left: 12px;
        margin-right: 12px;
    }
}

.navbar-icon {
    width: 2.5rem;
    z-index: 300;
    margin: 1rem;
    background-color: rgb(255 255 255 / 60%);
    border-radius: 20%;
}

.nav-button {
    height: 85px;
    display: flex;
    justify-content: space-between;
    z-index: 12;
}

.terms-con {
    padding-top: 6rem;
    .box {
        flex-direction: column;
        min-height: calc(100vh - 5rem);
        justify-content: center;
    }

    .text-content {
        width: 80%;
        margin: 0 auto;
    }
}

/* queries */

@media screen and (min-width: 1800px) {
    .footer {
        height: 8rem;
    }
}

@media screen and (max-width: 1150px) {
    .con {
        flex-direction: row;
    }
}

@media screen and (max-width: 850px) {
    .con {
        flex-direction: row;
    }

    #mainNav {
        height: 75px;
    }

    .footer {
        .link-con {
            margin-top: 10px;
            margin-left: 30px;
        }
        .icon {
            width: 20%;
        }
        .footer-box.first {
            padding: 1% 0 4% 0;
        }
        .footer-box {
            width: 100%;
            padding: 3% 0;
        }
    }

    .about-container {
        .box {
            padding-bottom: 100px;
        }
        .text-con {
            padding-top: 0;
        }
    }

    .text-box {
        width: 100%;
    }

    .main-content {
        width: 100%;
        max-width: none;
    }
}
