.con.home {
    background-color: #e2e2e2;

    .stage {
        padding: 3% 5%;
        margin: 0 auto;
        min-height: 800px;
        text-align: center;
        width: 100%;

        .opening-text-con {
            margin-top: 80px;
            margin-top: 80px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        h1 {
            text-transform: uppercase;
            font-size: 2.4rem;
            color: #ffffff;
            margin-bottom: -10px;
            background-color: rgb(82 137 139 / 50%);
            padding: 0 20px;
        }

        h2 {
            color: #ffffff;
            font-size: 1.7em;
            font-style: italic;
            background-color: #2ca4aa95;
            margin: 20px auto;
            // width: 30%;
        }

        @media screen and (max-width: 1150px) {
            .stage {
                width: 90%;
            }
        }
    }

    .animate-box {
        width: 100%;
        margin: 0 auto;
        background-color: #212529;

        text-align: center;

        .animate-character {
            text-transform: uppercase;
            background-image: linear-gradient(-225deg,
                    #00e8ff 0%,
                    #7abf2b 29%,
                    #9598ff 67%,
                    #87f293 100%);
            background-size: auto auto;
            background-clip: border-box;
            background-size: 200% auto;
            background-position: center;
            color: #fff;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: textclip 5s linear;
            display: inline-block;
            font-size: 4rem;
            font-weight: 500;
            font-family: "Montserrat", sans-serif;
            padding: 40px 0px;
            width: 80%;
            letter-spacing: 0.1em;

            &:hover {
                animation: textclip 5s linear infinite;
            }
        }
    }

    @keyframes textclip {
        to {
            background-position: 200% center;
        }
    }
}

@media screen and (max-width: 850px) {
    .home {
        .animate-character {
            font-size: 2rem !important;
            line-height: 1.5;
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 550px) {
    .con.home {
        .stage {
            h1 {
                font-size: 2rem;
            }

            h3 {
                position: absolute;
                top: 20%;
                margin: 0 auto;
            }
        }
    }
}